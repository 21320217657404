html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  /*font-family: "Helvetica Neue", Arial;*/
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spacer {
  display: block;
  height:20px;
  width: 100%;
}

fieldset {
  border: none;
  margin: 0;
  padding:0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
fieldset button {
  min-width: 300px;
  max-width: 400px;
}
fieldset .center {
  justify-content: center;
}

.searchFormGridItem {
  margin-bottom: 15px !important;
}
form.searchForm {}
form.searchForm fieldset.selectGroup {

}
form.searchForm fieldset.radioButtonGroup {
  padding-top: 20px;
}
form.searchForm fieldset.radioButtonGroup legend {
  padding-bottom: 15px;
}
form.searchForm fieldset.switchGroup {

}
form.searchForm fieldset.buttonGroup {

}
form.searchForm button {
  min-width:70%;
}

button.saveBtn {
  margin-top: 7px;
}
button.saved {
  color:#ccc !important;
}
.appBarTitle {margin-top: 7px !important;}

button.locationCardActionArea {}
button.locationCardActionArea:hover img{
  opacity:1;
}

.locationContainer {}
.locationContainer .infos {
  padding:20px 30px;
}
.locationContainer .infos h1 {
  font-size: 2rem;
}
.locationContainer .infos h2 {
  font-size: 1rem;
  margin-top:7px;
}
.locationContainer .map {}

.event span.eventType {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #FFF;
  margin-right: 0.8em;
}

dl.eventDetails {
  display: flex;
  flex-flow: row wrap;
  margin:0px;
  font-size: 0.9rem;
  /*border: solid #333;
  border-width: 1px 1px 0 0;*/
}
dl.eventDetails dt {
  flex-basis: 20%;
  padding: 4px 4px;
  text-align: right;
  font-weight: bold;
  /*background: #333;
  color: #fff;*/
}
dl.eventDetails dt::after {
  content: ":";
}
dl.eventDetails dd {
  flex-basis: 80%;
  flex-grow: 1;
  margin: 0;
  padding: 4px 4px;
  /*border-bottom: 1px solid #333;*/
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #efefef;
  text-align: center;
}


@media (max-width: 640px) {
  button.headerActionBtn {
    display:none !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
