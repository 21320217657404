/* -- Sprites */

.eventType {background-image: url('../img/pictos/sprites/eventTypes_30.png?2'); background-repeat: no-repeat; width: 34px; height: 32px; /*width: 29px; height: 30px;*/}
.style-0, .rock{ background-position: 2px 0; }
.style-1, .jazz{ background-position: 2px -80px;}
.style-2, .worldmusic{ background-position: 2px -160px; }
.style-3, .chanson{ background-position: 2px -240px; }
.style-4, .electro{ background-position: 2px -320px; }
.style-5, .groove{ background-position: 2px -400px; }
.style-6 { background-position: 2px -480px; }
.style-enfants {background-position: 2px -560px; }
.style-7, .classique{ background-position: 2px -640px; }
.style-8{ background-position: 2px -720px; }
.style-9{ background-position: 2px -800px; }
.style-10{ background-position: 2px -2000px; }

.small-eventType {background-image: url('../img/pictos/sprites/eventTypes_20.png?2'); background-repeat: no-repeat; width: 20px; height: 20px;}
.small-style-0, .small-rock{ background-position: 0 0; }
.small-style-1, .small-jazz{ background-position: 0 -70px; }
.small-style-2, .small-worldmusic{ background-position: 0 -140px; }
.small-style-3, .small-chanson{ background-position: 0 -210px; }
.small-style-4, .small-electro{ background-position: 0 -280px; }
.small-style-5, .small-groove{ background-position: 0 -350px; }
.small-style-6 { background-position: 0 -420px; }
.small-style-enfants { background-position: 0 -490px; }
.small-style-7, .small-classique{ background-position: 0 -560px; }
.small-style-8{ background-position: 0 -630px; }
.small-style-9{ background-position: 0 -700px; }
.small-style-10{ background-position: 0 -3000px; }

/*
.eventType {background-image: url('../img/pictos/sprites/eventTypes_30.png?2'); background-repeat: no-repeat; width: 30px; height: 30px;}
.style-0{ background-position: 0px 0; }
.style-1{ background-position: 0px -80px;}
.style-2{ background-position: 0px -160px; }
.style-3{ background-position: 0px -240px; }
.style-4{ background-position: 0px -320px; }
.style-5{ background-position: 0px -400px; }
.style-6{ background-position: 0px -480px; }
.style-enfants {background-position: 0px -560px; }
.style-7{ background-position: 0px -640px; }
.style-8{ background-position: 0px -720px; }
.style-9{ background-position: 0px -800px; }
.style-10{ background-position: 0px -2000px; }

.small-eventType {background-image: url('../img/pictos/sprites/eventTypes_20.png?2'); background-repeat: no-repeat; width: 20px; height: 20px;}
.small-style-0{ background-position: 0 0; }
.small-style-1{ background-position: 0 -70px; }
.small-style-2{ background-position: 0 -140px; }
.small-style-3{ background-position: 0 -210px; }
.small-style-4{ background-position: 0 -280px; }
.small-style-5{ background-position: 0 -350px; }
.small-style-6{ background-position: 0 -420px; }
.small-style-enfants{ background-position: 0 -490px; }
.small-style-7{ background-position: 0 -560px; }
.small-style-8{ background-position: 0 -630px; }
.small-style-9{ background-position: 0 -700px; }
.small-style-10{ background-position: 0 -3000px; }
*/

.locationZone {background-image: url('../img/pictos/sprites/pictos_22.png'); background-repeat: no-repeat; width: 22px; height: 22px;}
.region-a{ background-position: 5px -218px; }
.region-b{ background-position: 5px -290px; }
.region-c{ background-position: 5px -362px; }
.region-d{ background-position: 5px -434px; }
.region-e{ background-position: 5px -506px; }
.region-f{ background-position: 5px -578px; }
.region-g{ background-position: 5px -650px; }
.region-h{ background-position: 5px -722px; }
.region-i{ background-position: 5px -794px; }
.region-j{ background-position: 5px -866px; }
.region-k{ background-position: 5px -938px; }
.region-l{ background-position: 5px -1010px; }
.region-m{ background-position: 5px -1082px; }
.region-n{ background-position: 5px -1154px; }
.region-o{ background-position: 5px -1226px; }
.region-p{ background-position: 5px -1298px; }
.region-q{ background-position: 5px -1370px; }
.region-r{ background-position: 5px -1442px; }
.region-s{ background-position: 5px -1514px; }
.region-t{ background-position: 5px -1586px; }
.region-u{ background-position: 5px -1658px; }
.region-v{ background-position: 5px -1730px; }
.region-w{ background-position: 5px -1802px; }
.region-x{ background-position: 5px -1874px; }
.region-autres{ background-position: 5px -2200px; }
